$(function() {
  /* make navbar fixed when user scrolls past it */
  $(window).scroll(function(event) {
    if ($(window).scrollTop() >= ($('.banner').height() - 88)) {
      $('.navbar').removeClass('navbar-index');
    } else {
      $('.navbar').addClass('navbar-index');
    };
  });

  /* banner button */
  $(".banner-button").click(function() {
    $('html, body').animate({
        scrollTop: $(".article-title").offset().top - 48
    }, 700);
  });
});